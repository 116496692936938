/**
 * When adding new key, make sure you add permissionKey to correct menuItem
 * @link {menuItems}
 */
export enum UserPermissionKey {
  DASHBOARD = 'dashboard',
  CALENDAR = 'calendar',
  CLINICS = 'clinics',
  CLINIC_SETTINGS = 'clinic-settings',
  CUSTOMERS = 'customers',
  PATIENTS = 'patients',
  RECORDS = 'records',
  RECORD_TEMPLATES = 'record-templates',
  SEGMENTS = 'segments',
  SHIFT_PLANNER = 'shift',
  TIME_TRACKER = 'tracker',
  INVOICES = 'invoices',
  DRAFTS = 'drafts',
  DRAFT_TEMPLATES = 'templates',
  QUOTES = 'quotes',
  REPORTS = 'reports',
  WORK_TIME = 'reports.worktime',
  STAFF = 'staff',
  BUNDLES = 'bundles',
  ITEMS = 'items',
  EQUIPMENT = 'equipment',
  ROOMS = 'rooms',
  DOCUMENT_TEMPLATES = 'document-templates',
  EMAIL_TEMPLATES = 'email-templates',
  TEST_RESULTS = 'test-results',
}

export interface UserPermission {
  [key: string]: any;
  display?: boolean;
}

export interface UserPermissions {
  [UserPermissionKey.DASHBOARD]: {
    display: boolean;
    items: string[];
  };
  [UserPermissionKey.CALENDAR]: {
    add: boolean;
    display: boolean;
    edit: boolean;
    own: boolean;
    rooms: boolean;
    staff: boolean;
  };
  [UserPermissionKey.CUSTOMERS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    email: boolean;
    sms: boolean;
  };
  [UserPermissionKey.PATIENTS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    export: boolean;
    email: boolean;
  };
  [UserPermissionKey.RECORDS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    email: boolean;
    export: boolean;
  };
  [UserPermissionKey.RECORD_TEMPLATES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.SEGMENTS]: {
    add: boolean;
    display: boolean;
    edit: boolean;
    email: boolean;
    sms: boolean;
  };
  [UserPermissionKey.SHIFT_PLANNER]: {
    add: boolean;
    display: boolean;
    duplicate: boolean;
    edit: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.TIME_TRACKER]: {
    add: boolean;
    display: boolean;
    edit: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.INVOICES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    email: boolean;
    export: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.DRAFTS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    export: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.QUOTES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    email: boolean;
    export: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.DRAFT_TEMPLATES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
    export: boolean;
    own: boolean;
    staff: boolean;
  };
  [UserPermissionKey.REPORTS]: {
    [UserPermissionKey.WORK_TIME]: {
      display: boolean;
      own: boolean;
      staff: boolean;
    };
  };
  [UserPermissionKey.STAFF]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.BUNDLES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.ITEMS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.EQUIPMENT]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.ROOMS]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
  [UserPermissionKey.DOCUMENT_TEMPLATES]: {
    add: boolean;
    detail: boolean;
    display: boolean;
    edit: boolean;
  };
}
