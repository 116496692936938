import { CrmMenuItem } from 'common-module/menu';

import { UserPermissionKey } from '~/api/user/user.permissions';

import { RoutesConfig } from './routes.config';

export enum MenuItemKeys {
  DASHBOARD = 'dashboard.title',
  CLINICS = 'generic.clinics',
  CLINIC_SETTINGS = 'clinicSettings.title',
  CALENDAR = 'generic.calendar',
  CUSTOMERS = 'customers.title',
  PATIENTS = 'patients.title',
  RECORDS = 'generic.medicalRecords',
  RECORD_TEMPLATES = 'recordTemplates.title',
  SEGMENTS = 'generic.segments',
  PLANNING = 'generic.planning',
  SHIFT_PLANNER = 'generic.shiftPlanner',
  TIME_TRACKER = 'generic.timeTracking',
  INVOICING = 'invoicing.title',
  INVOICES = 'generic.invoices',
  DRAFTS = 'generic.drafts',
  DRAFT_TEMPLATES = 'generic.draftTemplates',
  QUOTES = 'generic.quotes',
  REPORTING = 'generic.reporting',
  WORK_TIME = 'reports.shiftTime',
  RESOURCES = 'generic.resources',
  STAFF = 'generic.staff',
  BUNDLES = 'templates.bundles.title',
  ALL_ITEMS = 'generic.allItems',
  TANGIBLE = 'generic.tangibleItems',
  INTANGIBLE = 'generic.intangibleItems',
  EQUIPMENT = 'generic.equipment',
  ROOMS = 'generic.rooms',
  DOCUMENT_TEMPLATES = 'documentTemplates.title',
  EMAIL_TEMPLATES = 'emailTemplates.title',
  TEST_RESULTS = 'tests.menuTitle',
  TEMPLATES = 'generic.templates',
}

export interface MenuItem extends CrmMenuItem {
  permissionKey?: UserPermissionKey;
  desktopAllowed?: boolean;
  tabletAllowed?: boolean;
  children?: MenuItem[];
}

export const menuItems: MenuItem[] = [
  {
    key: MenuItemKeys.DASHBOARD,
    icon: 'dashboard',
    routeKey: RoutesConfig.DASHBOARD,
    permissionKey: UserPermissionKey.DASHBOARD,
    desktopAllowed: true,
    tabletAllowed: true,
  },
  {
    key: MenuItemKeys.CLINICS,
    icon: 'global',
    routeKey: RoutesConfig.CLINICS,
    permissionKey: UserPermissionKey.CLINICS,
    desktopAllowed: true,
    tabletAllowed: false,
  },
  {
    key: MenuItemKeys.CALENDAR,
    icon: 'calendar',
    routeKey: RoutesConfig.CALENDAR,
    permissionKey: UserPermissionKey.CALENDAR,
    desktopAllowed: true,
    tabletAllowed: true,
  },
  {
    key: MenuItemKeys.CUSTOMERS,
    icon: 'user',
    routeKey: RoutesConfig.CUSTOMERS,
    permissionKey: UserPermissionKey.CUSTOMERS,
    desktopAllowed: true,
    tabletAllowed: true,
  },
  {
    key: MenuItemKeys.PATIENTS,
    icon: 'idcard',
    routeKey: RoutesConfig.PATIENTS,
    permissionKey: UserPermissionKey.PATIENTS,
    desktopAllowed: true,
    tabletAllowed: true,
  },
  {
    key: MenuItemKeys.RECORDS,
    icon: 'folder-open',
    routeKey: '',
    desktopAllowed: true,
    tabletAllowed: true,
    children: [
      {
        key: MenuItemKeys.RECORDS,
        routeKey: RoutesConfig.RECORDS,
        permissionKey: UserPermissionKey.RECORDS,
        desktopAllowed: true,
        tabletAllowed: true,
      },
      {
        key: MenuItemKeys.TEST_RESULTS,
        routeKey: RoutesConfig.TEST_RESULT,
        permissionKey: UserPermissionKey.TEST_RESULTS,
        desktopAllowed: true,
        tabletAllowed: true,
      },
      {
        key: MenuItemKeys.RECORD_TEMPLATES,
        routeKey: RoutesConfig.RECORD_TEMPLATES,
        permissionKey: UserPermissionKey.RECORD_TEMPLATES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
  {
    key: MenuItemKeys.SEGMENTS,
    icon: 'tag',
    routeKey: RoutesConfig.SEGMENTS,
    permissionKey: UserPermissionKey.SEGMENTS,
    desktopAllowed: true,
    tabletAllowed: false,
  },
  {
    key: MenuItemKeys.PLANNING,
    icon: 'calendar',
    routeKey: '',
    desktopAllowed: true,
    tabletAllowed: false,
    children: [
      {
        key: MenuItemKeys.SHIFT_PLANNER,
        routeKey: RoutesConfig.SHIFT_PLANNER,
        permissionKey: UserPermissionKey.SHIFT_PLANNER,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.TIME_TRACKER,
        routeKey: RoutesConfig.TIME_TRACKER,
        permissionKey: UserPermissionKey.TIME_TRACKER,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
  {
    key: MenuItemKeys.INVOICING,
    icon: 'dollar',
    routeKey: '',
    desktopAllowed: true,
    tabletAllowed: false,
    children: [
      {
        key: MenuItemKeys.INVOICES,
        routeKey: RoutesConfig.INVOICES,
        permissionKey: UserPermissionKey.INVOICES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.DRAFTS,
        routeKey: RoutesConfig.DRAFTS,
        permissionKey: UserPermissionKey.DRAFTS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.QUOTES,
        routeKey: RoutesConfig.QUOTES,
        permissionKey: UserPermissionKey.QUOTES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
  {
    key: MenuItemKeys.REPORTING,
    icon: 'solution',
    routeKey: RoutesConfig.REPORTS,
    desktopAllowed: true,
    tabletAllowed: false,
    children: [
      {
        key: MenuItemKeys.WORK_TIME,
        routeKey: `${RoutesConfig.WORK_TIME_REPORT}`,
        permissionKey: UserPermissionKey.WORK_TIME,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
  {
    key: MenuItemKeys.RESOURCES,
    icon: 'gold',
    routeKey: '',
    desktopAllowed: true,
    tabletAllowed: false,
    children: [
      {
        key: MenuItemKeys.TANGIBLE,
        routeKey: RoutesConfig.TANGIBLE,
        permissionKey: UserPermissionKey.ITEMS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.INTANGIBLE,
        routeKey: RoutesConfig.INTANGIBLE,
        permissionKey: UserPermissionKey.ITEMS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.ALL_ITEMS,
        routeKey: RoutesConfig.ALL_ITEMS,
        permissionKey: UserPermissionKey.ITEMS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.BUNDLES,
        routeKey: RoutesConfig.BUNDLES,
        permissionKey: UserPermissionKey.BUNDLES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.DRAFT_TEMPLATES,
        routeKey: RoutesConfig.DRAFT_TEMPLATES,
        permissionKey: UserPermissionKey.DRAFT_TEMPLATES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.ROOMS,
        routeKey: RoutesConfig.ROOMS,
        permissionKey: UserPermissionKey.ROOMS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.EQUIPMENT,
        routeKey: RoutesConfig.EQUIPMENT,
        permissionKey: UserPermissionKey.EQUIPMENT,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.STAFF,
        routeKey: RoutesConfig.STAFF,
        permissionKey: UserPermissionKey.STAFF,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.CLINIC_SETTINGS,
        routeKey: RoutesConfig.CLINIC_SETTINGS,
        permissionKey: UserPermissionKey.CLINIC_SETTINGS,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
  {
    key: MenuItemKeys.TEMPLATES,
    icon: 'icons:menu-templates',
    routeKey: '',
    desktopAllowed: true,
    tabletAllowed: false,
    children: [
      {
        key: MenuItemKeys.DOCUMENT_TEMPLATES,
        routeKey: RoutesConfig.DOCUMENT_TEMPLATES,
        permissionKey: UserPermissionKey.DOCUMENT_TEMPLATES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
      {
        key: MenuItemKeys.EMAIL_TEMPLATES,
        routeKey: RoutesConfig.EMAIL_TEMPLATES,
        permissionKey: UserPermissionKey.EMAIL_TEMPLATES,
        desktopAllowed: true,
        tabletAllowed: false,
      },
    ],
  },
];
